
  import { Component, Vue } from 'vue-property-decorator'
  import FirstSlide from '@/components/FirstSlide.vue'
  import SecondSlide from '@/components/SecondSlide.vue'
  import ThirdSlide from '@/components/ThirdSlide.vue'
  import FourthSlide from '@/components/FourthSlide.vue'
  import FifthSlide from '@/components/FifthSlide.vue'

  @Component({
    components: {
      'first-slide': FirstSlide,
      'second-slide': SecondSlide,
      'third-slide': ThirdSlide,
      'fourth-slide': FourthSlide,
      'fifth-slide': FifthSlide,
    }
  })
  export default class Home extends Vue {}
