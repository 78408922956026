import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import Vue from 'vue'

Vue.mixin({
  methods: {
    setActiveIndex(index, disappear) {
      this.$store.commit('GifStore/setActive', {index, disappear})
    },
    checkPosition(data, index, disappear = false, keepLooking = false) {
      if (!disappear) {
        if (data.elementOffsetTopInViewHeight <= data.inViewHeight / 2) {
          if (data.elementOffsetTopInViewHeight >= data.inViewHeight / -2) {
            this.setActiveIndex(index, false)
          }
        }
      } else {
        if (data.elementOffsetTopInViewHeight <= 0) {
          this.setActiveIndex(index, true)
        } else {
          this.setActiveIndex(null, false)
        }
      }
    }
  }
})

/**
 * Module Augument
 */
declare module 'vue/types/vue' {
  interface Vue {
    setActiveIndex: (index: number | null, disappear: boolean) => void
    checkPosition: (data: any, index: number, disappear?: boolean, keepLooking?: boolean) => void
  }
}

@Module({
  namespaced: true
})
export default class GifStore extends VuexModule {
  activeGif: number = 0
  disappear: boolean = false

  @Mutation
  setActive(data: {index: number, disappear: boolean}) {
    const { index, disappear } = data
    if (index !== null) {
      this.activeGif = index
    }
    this.disappear = disappear
  }

  get active() {
    return this.activeGif
  }

  get hide() {
    return this.disappear
  }
}
