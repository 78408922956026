
  import { Component, Vue } from 'vue-property-decorator'
  // @ts-ignore
  import InView from 'inview'

  @Component
  export default class SecondSlide extends Vue {
    mounted() {
      InView(this.$refs.slide, (isInView: any, data: any) => {
        if (isInView) {
          this.checkPosition(data, 1)
        }
      })
    }
  }
