
  import { Component, Vue } from 'vue-property-decorator'
  // @ts-ignore
  import InView from 'inview'

  @Component
  export default class FirstSlide extends Vue {
    // @ts-ignore
    gtag: any = gtag

    mounted() {
      InView(this.$refs.slide, (isInView: any, data: any) => {
        if (isInView) {
          this.checkPosition(data, 0)
        }
      })
    }
    scroll() {
      // @ts-ignore
      const height = this.$refs.container.offsetHeight
      window.scrollTo({
        top: height + 64,
        behavior: 'smooth'
      })
    }

    openLink(platform: 'android' | 'apple') {
      this.gtag('event', 'click', {
        event_category: 'store',
        event_label: platform
      })
      window.open(
        platform === 'android'
          ? 'https://play.google.com/store/apps/details?id=it.almalaurea.almago'
          : 'https://apps.apple.com/us/app/it.almalaurea.almago/id1477988233?l=it&ls=1',
        '_blank'
      )
    }
  }
