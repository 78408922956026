
  import { Component, Vue } from 'vue-property-decorator'
  import { mapGetters } from 'vuex'

  @Component({
    computed: {
      ...mapGetters({
        activeGif: 'GifStore/active',
        hide: 'GifStore/hide',
      })
    }
  })
  export default class Phone extends Vue {}
