
  import { Component, Vue } from 'vue-property-decorator'

  @Component
  export default class ToTop extends Vue {
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
