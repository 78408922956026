
  import { Component, Vue } from 'vue-property-decorator'
  import Footer from '@/components/Footer.vue'
  import ToTop from '@/components/ToTop.vue'

  @Component({
    components: {
      'alma-footer': Footer,
      'to-top': ToTop
    }
  })
  export default class App extends Vue {}
